import Layout from "../UI/Layout/Layout";
import {
  StyledDataProtection,
  CustomHero,
  CustomImage,
} from "./DataProtection.Styled";
import heroImage from "../../assets/image/imprint/hero.jpg";
import logo from "../../assets/image/logo.png";
import Container from "../UI/Container/Container";
import Separator from "../UI/Separator/Separator";
import Headline from "../UI/Headline/Headline";
import { useSelector } from "react-redux";

const DataProtection = () => {
  const theme = useSelector((s) => s.theme);
  const translation = useSelector((s) => s.translation);
  return (
    <Layout>
      <CustomHero img={heroImage}>
        <Container>
          <a href="/">
            <CustomImage src={logo} />
          </a>
          <Separator color={theme.color.white} />
        </Container>
      </CustomHero>
      <StyledDataProtection>
        <Container>
          <Headline
            text={translation.dataProtection.headline}
            withSeparator={true}
            color={theme.color.blue}
            margin="50px 0px"
          />
          <div className="headline">1. Datenschutz auf einen Blick</div>
          <div className="headline">Allgemeine Hinweise</div>
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>
          <div className="headline">Datenerfassung auf dieser Website</div>
          <div className="headline">
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </div>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
            „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
            entnehmen.
          </p>
          <div className="headline">Wie erfassen wir Ihre Daten?</div>
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
          </p>
          <p>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
            Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
            allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie diese Website betreten.
          </p>
          <div className="headline">Wofür nutzen wir Ihre Daten?</div>
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>
          <div className="headline">
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </div>
          <p>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, können Sie diese Einwilligung
            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
            unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>
          <p>
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
            sich jederzeit an uns wenden.
          </p>
          <div className="headline">2. Hosting</div>
          <div className="headline">Hetzner</div>
          <p>
            Wir hosten unsere Website bei Hetzner. Anbieter ist die Hetzner
            Online GmbH, Industriestr. 25, 91710 Gunzenhausen (nachfolgend
            Hetzner).
          </p>
          <p>
            Details entnehmen Sie der Datenschutzerklärung von Hetzner:
            https://www.hetzner.com/de/rechtliches/datenschutz.
          </p>
          <p>
            Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
            möglichst zuverlässigen Darstellung unserer Website. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
            Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
            oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
            ist jederzeit widerrufbar.
          </p>
          <div className="headline">Auftragsverarbeitung</div>
          <p>
            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
            genannten Anbieter geschlossen. Hierbei handelt es sich um einen
            datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet,
            dass dieser die personenbezogenen Daten unserer Websitebesucher nur
            nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
          <div className="headline">
            3. Allgemeine Hinweise und Pflichtinformationen
          </div>
          <div className="headline">Datenschutz</div>
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </p>
          <div className="headline">Hinweis zur verantwortlichen Stelle</div>
          <p>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </p>
          <div className="headline">Wilhelm Hilpert Hausverwaltung GmbH</div>
          <p>Mühlenstraße 35</p>
          <p>10243 Berlin</p>
          <p>Tel.: 030 44 38 43 0</p>
          <p>Fax: 030 44 38 43 299</p>
          <p>E-mail: office@hilpert-hausverwaltung.de</p>
          <p>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z. B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>
          <div className="headline">Speicherdauer</div>
          <p>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
            ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
            zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
            wir keine anderen rechtlich zulässigen Gründe für die Speicherung
            Ihrer personenbezogenen Daten haben (z. B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
            erfolgt die Löschung nach Fortfall dieser Gründe.
          </p>
          <div className="headline">
            Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung
            auf dieser Website
          </div>
          <p>
            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
            wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1
            lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
            Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im
            Falle einer ausdrücklichen Einwilligung in die Übertragung
            personenbezogener Daten in Drittstaaten erfolgt die
            Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a
            DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff
            auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting)
            eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf
            Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
            widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
            Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten
            wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
            Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung
            einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von
            Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf
            Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
            DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
            Rechtsgrundlagen wird in den folgenden Absätzen dieser
            Datenschutzerklärung informiert.
          </p>
          <div className="headline">Datenschutzbeauftragter</div>
          <p>
            Wir haben für unser Unternehmen einen Datenschutzbeauftragten
            bestellt.
          </p>
          <p>
            Rechtsanwaltskanzlei Wetzel
            <br />
            Datenschutz, IT- & Vertragsrecht
            <br />
            RA Marcel Wetzel
          </p>
          <p>
            Theodor-Heuss-Platz 4<br />
            14052 Berlin
          </p>
          <p>
            E-Mail: mail@wetzel.berlin
            <br />
            Tel.: 030 / 895 66 160
            <br />
            Fax.: 030 / 895 66 161
            <br />
            www.wetzel.berlin
          </p>
          <div className="headline">
            Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
          </div>
          <p>
            Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den
            USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten.
            Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in
            diese Drittstaaten übertragen und dort verarbeitet werden. Wir
            weisen darauf hin, dass in diesen Ländern kein mit der EU
            vergleichbares Datenschutzniveau garantiert werden kann.
            Beispielsweise sind US-Unternehmen dazu verpflichtet,
            personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne
            dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es
            kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B.
            Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
            Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
            Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
          </p>
          <div className="headline">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </div>
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
          <div className="headline">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </div>
          <p>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
            ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
            DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
            VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
            DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.
            DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
            EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
            MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
            GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
            UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
            (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
          </p>
          <p>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
            BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
            VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
            DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
            SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
            WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
            NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH
            ART. 21 ABS. 2 DSGVO).
          </p>
          <div className="headline">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </div>
          <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </p>
          <div className="headline">Recht auf Datenübertragbarkeit</div>
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>
          <div className="headline">SSL- bzw. TLS-Verschlüsselung</div>
          <p>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>
          <p>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </p>
          <div className="headline">Auskunft, Löschung und Berichtigung</div>
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
            uns wenden.
          </p>
          <div className="headline">
            Recht auf Einschränkung der Verarbeitung
          </div>
          <p>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen:
          </p>
          <ul>
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </li>
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten - von ihrer Speicherung
            abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
          <div className="headline">Widerspruch gegen Werbe-E-Mails</div>
          <p>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </p>
          <div className="headline">4. Datenerfassung auf dieser Website</div>
          <div className="headline">Cookies</div>
          <p>
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
            kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
            Sie werden entweder vorübergehend für die Dauer einer Sitzung
            (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
            Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
            automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
            gespeichert, bis Sie diese selbst löschen oder eine automatische
            Löschung durch Ihren Webbrowser erfolgt.
          </p>
          <p>
            Teilweise können auch Cookies von Drittunternehmen auf Ihrem
            Endgerät gespeichert werden, wenn Sie unsere Seite betreten
            (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
            bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
            Abwicklung von Zahlungsdienstleistungen).
          </p>
          <p>
            Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
            technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
            funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige
            von Videos). Andere Cookies dienen dazu, das Nutzerverhalten
            auszuwerten oder Werbung anzuzeigen.
          </p>
          <p>
            Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen
            erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur
            Optimierung der Website (z. B. Cookies zur Messung des Webpublikums)
            erforderlich sind (notwendige Cookies), werden auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
            Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
            berechtigtes Interesse an der Speicherung von notwendigen Cookies
            zur technisch fehlerfreien und optimierten Bereitstellung seiner
            Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und
            vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt
            die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die Einwilligung
            ist jederzeit widerrufbar.
          </p>
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>
          <p>
            Soweit Cookies von Drittunternehmen oder zu Analysezwecken
            eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
            Datenschutzerklärung gesondert informieren und ggf. eine
            Einwilligung abfragen.
          </p>
          <div className="headline">Server-Log-Dateien</div>
          <p>
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind:
          </p>
          <ul>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
          </ul>
          <p>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
            nicht vorgenommen.
          </p>
          <p>
            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
            der technisch fehlerfreien Darstellung und der Optimierung seiner
            Website - hierzu müssen die Server-Log-Files erfasst werden.
          </p>
          <div className="headline">Kontaktformular</div>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen - insbesondere
            Aufbewahrungsfristen - bleiben unberührt.
          </p>
          <div className="headline">
            Anfrage per E-Mail, Telefon oder Telefax
          </div>
          <p>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten
            verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
            Ihres Anliegens). Zwingende gesetzliche Bestimmungen - insbesondere
            gesetzliche Aufbewahrungsfristen - bleiben unberührt.
          </p>
          <div className="headline">5. eCommerce und Zahlungsanbieter</div>
          <div className="headline">
            Verarbeiten von Kunden- und Vertragsdaten
          </div>
          <p>
            Wir erheben, verarbeiten und nutzen personenbezogene Kunden- und
            Vertragsdaten zur Begründung, inhaltlichen Ausgestaltung und
            Änderung unserer Vertragsbeziehungen. Personenbezogene Daten über
            die Inanspruchnahme dieser Website (Nutzungsdaten) erheben,
            verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem
            Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder
            abzurechnen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. b DSGVO.
          </p>
          <p>
            Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
            Beendigung der Geschäftsbeziehung und Ablauf der ggf. bestehenden
            gesetzlichen Aufbewahrungsfristen gelöscht. Gesetzliche
            Aufbewahrungsfristen bleiben unberührt.
          </p>
          <div className="headline">6. Audio- und Videokonferenzen</div>
          <div className="headline">Datenverarbeitung</div>
          <p>
            Für die Kommunikation mit unseren Kunden setzen wir unter anderen
            Online-Konferenz-Tools ein. Die im Einzelnen von uns genutzten Tools
            sind unten aufgelistet. Wenn Sie mit uns per Video- oder
            Audiokonferenz via Internet kommunizieren, werden Ihre
            personenbezogenen Daten von uns und dem Anbieter des jeweiligen
            Konferenz-Tools erfasst und verarbeitet.
          </p>
          <p>
            Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung
            der Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre
            Telefonnummer). Ferner verarbeiten die Konferenz-Tools die Dauer der
            Konferenz, Beginn und Ende (Zeit) der Teilnahme an der Konferenz,
            Anzahl der Teilnehmer und sonstige „Kontextinformationen“ im
            Zusammenhang mit dem Kommunikationsvorgang (Metadaten).
          </p>
          <p>
            Des Weiteren verarbeitet der Anbieter des Tools alle technischen
            Daten, die zur Abwicklung der Online-Kommunikation erforderlich
            sind. Dies umfasst insbesondere IP-Adressen, MAC-Adressen,
            Geräte-IDs, Gerätetyp, Betriebssystemtyp und -version,
            Client-Version, Kameratyp, Mikrofon oder Lautsprecher sowie die Art
            der Verbindung.
          </p>
          <p>
            Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder in
            sonstiger Weise bereitgestellt werden, werden diese ebenfalls auf
            den Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten
            zählen insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten,
            Voicemails hochgeladene Fotos und Videos, Dateien, Whiteboards und
            andere Informationen, die während der Nutzung des Dienstes geteilt
            werden.
          </p>
          <p>
            Bitte beachten Sie, dass wir nicht vollumfänglich Einfluss auf die
            Datenverarbeitungsvorgänge der verwendeten Tools haben. Unsere
            Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik
            des jeweiligen Anbieters. Weitere Hinweise zur Datenverarbeitung
            durch die Konferenztools entnehmen Sie den Datenschutzerklärungen
            der jeweils eingesetzten Tools, die wir unter diesem Text aufgeführt
            haben.
          </p>
          <div className="headline">Zweck und Rechtsgrundlagen</div>
          <p>
            Die Konferenz-Tools werden genutzt, um mit angehenden oder
            bestehenden Vertragspartnern zu kommunizieren oder bestimmte
            Leistungen gegenüber unseren Kunden anzubieten (Art. 6 Abs. 1 lit. b
            DSGVO). Des Weiteren dient der Einsatz der Tools der allgemeinen
            Vereinfachung und Beschleunigung der Kommunikation mit uns bzw.
            unserem Unternehmen (berechtigtes Interesse im Sinne von Art. 6 Abs.
            1 lit. f DSGVO). Soweit eine Einwilligung abgefragt wurde, erfolgt
            der Einsatz der betreffenden Tools auf Grundlage dieser
            Einwilligung; die Einwilligung ist jederzeit mit Wirkung für die
            Zukunft widerrufbar.
          </p>

          <div className="headline">Speicherdauer</div>
          <p>
            Die unmittelbar von uns über die Video- und Konferenz-Tools
            erfassten Daten werden von unseren Systemen gelöscht, sobald Sie uns
            zur Löschung auffordern, Ihre Einwilligung zur Speicherung
            widerrufen oder der Zweck für die Datenspeicherung entfällt.
            Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie
            löschen. Zwingende gesetzliche Aufbewahrungsfristen bleiben
            unberührt.
          </p>
          <p>
            Auf die Speicherdauer Ihrer Daten, die von den Betreibern der
            Konferenz-Tools zu eigenen Zwecken gespeichert werden, haben wir
            keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte
            direkt bei den Betreibern der Konferenz-Tools.
          </p>
          <div className="headline">Eingesetzte Konferenz-Tools</div>
          <p>Wir setzen folgende Konferenz-Tools ein:</p>
          <div className="headline">Zoom</div>
          <p>
            Wir nutzen Zoom. Anbieter dieses Dienstes ist die Zoom
            Communications Inc., San Jose, 55 Almaden Boulevard, 6th Floor, San
            Jose, CA 95113, USA. Details zur Datenverarbeitung entnehmen Sie der
            Datenschutzerklärung von Zoom: https://zoom.us/de-de/privacy.html.
          </p>
          <p>
            Die Datenübertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gestützt. Details finden
            Sie hier: https://zoom.us/de-de/privacy.html.
          </p>
          <div className="headline">Auftragsverarbeitung</div>
          <p>
            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
            genannten Anbieter geschlossen. Hierbei handelt es sich um einen
            datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet,
            dass dieser die personenbezogenen Daten unserer Websitebesucher nur
            nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
          <div className="headline">7. Eigene Dienste</div>
          <div className="headline">Umgang mit Bewerberdaten</div>
          <p>
            Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B.
            per E-Mail, postalisch oder via Online-Bewerberformular). Im
            Folgenden informieren wir Sie über Umfang, Zweck und Verwendung
            Ihrer im Rahmen des Bewerbungsprozesses erhobenen personenbezogenen
            Daten. Wir versichern, dass die Erhebung, Verarbeitung und Nutzung
            Ihrer Daten in Übereinstimmung mit geltendem Datenschutzrecht und
            allen weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten
            streng vertraulich behandelt werden.
          </p>
          <div className="headline">Umfang und Zweck der Datenerhebung </div>
          <p>
            Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre
            damit verbundenen personenbezogenen Daten (z. B. Kontakt- und
            Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
            Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die
            Begründung eines Beschäftigungsverhältnisses erforderlich ist.
            Rechtsgrundlage hierfür ist § 26 BDSG nach deutschem Recht
            (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b
            DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine
            Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die
            Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen Daten
            werden innerhalb unseres Unternehmens ausschließlich an Personen
            weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt
            sind.
          </p>
          <p>
            Sofern die Bewerbung erfolgreich ist, werden die von Ihnen
            eingereichten Daten auf Grundlage von § 26 BDSG und Art. 6 Abs. 1
            lit. b DSGVO zum Zwecke der Durchführung des
            Beschäftigungsverhältnisses in unseren Datenverarbeitungssystemen
            gespeichert.
          </p>
          <div className="headline">Aufbewahrungsdauer der Daten</div>
          <p>
            Sofern wir Ihnen kein Stellenangebot machen können, Sie ein
            Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten
            wir uns das Recht vor, die von Ihnen übermittelten Daten auf
            Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f
            DSGVO) bis zu 6 Monate ab der Beendigung des Bewerbungsverfahrens
            (Ablehnung oder Zurückziehung der Bewerbung) bei uns aufzubewahren.
            Anschließend werden die Daten gelöscht und die physischen
            Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient insbesondere
            Nachweiszwecken im Falle eines Rechtsstreits. Sofern ersichtlich
            ist, dass die Daten nach Ablauf der 6-Monatsfrist erforderlich sein
            werden (z. B. aufgrund eines drohenden oder anhängigen
            Rechtsstreits), findet eine Löschung erst statt, wenn der Zweck für
            die weitergehende Aufbewahrung entfällt.
          </p>
          <p>
            Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine
            entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt
            haben oder wenn gesetzliche Aufbewahrungspflichten der Löschung
            entgegenstehen.
          </p>
        </Container>
      </StyledDataProtection>
    </Layout>
  );
};

export default DataProtection;
